// ../utils/dist/string.js
var replaceDiacritics = (inputString) => {
  return inputString.replace(/æ/g, "ae").replace(/ø/g, "oe").replace(/å/g, "aa").replace(/é|ê|è/g, "e").replace(/û/g, "u").replace(/î/g, "i").replace(/ô/g, "o").replace(/å/g, "a").replace(/ä/g, "a").replace(/ö/g, "o");
};
var slugify = (inputString) => {
  const lowerCase = inputString.trim().toLowerCase();
  return replaceDiacritics(lowerCase).replace(/ /g, "-").replace(/[^a-z0-9-]/g, "-").replace(/-+/g, "-").replace(/^-/, "").replace(/-$/, "");
};

// src/index.ts
var VGTVCategory = {
  NEWS: 1,
  SPORT: 3,
  FOOTBALL: 52,
  ENTERTAINMENT: 100324,
  SPOTLIGHT: 5,
  VIRAL: 279,
  DOCUMENTARY: 121
};
var isDocumentary = (asset) => {
  const { category } = asset;
  return category.id === VGTVCategory.DOCUMENTARY || category.parentId === VGTVCategory.DOCUMENTARY;
};
var isEntertainment = (asset) => {
  const { category } = asset;
  return category.id === VGTVCategory.ENTERTAINMENT || category.parentId === VGTVCategory.ENTERTAINMENT;
};
var isEpisode = (asset) => {
  var _a;
  return ((_a = asset.series) == null ? void 0 : _a.episodeNumber) !== void 0;
};
var getAssetAccessLevel = (asset) => {
  var _a, _b;
  const access = (_b = (_a = asset == null ? void 0 : asset.additional) == null ? void 0 : _a.access) != null ? _b : {};
  return Object.keys(access).length > 0 ? Object.keys(access)[0] : void 0;
};
var getAssetLevel = (accessControl, provider) => {
  const assetLevel = Object.keys(accessControl != null ? accessControl : {});
  if (assetLevel.length > 0) {
    if (assetLevel.includes("login") || provider === "vgtv" && assetLevel.includes("premium")) {
      return "Login";
    }
    return "Paid";
  }
  return "Free";
};
var getAssetLevelName = (accessControl) => {
  const assetLevel = Object.keys(accessControl);
  if (assetLevel.length > 0) {
    return assetLevel.join(",");
  }
  return "Free";
};
var getAssetDisplayTitle = (asset) => {
  return asset.title.replace(new RegExp(`^${asset.category.title}: `, "i"), "").replace(new RegExp("^VG Nyheter: ", "i"), "").replace(/^DIREKTE: /, "").replace(/^DIREKTE (kl([.]|) |)([0-9]{2}\.[0-9]{2}): /, "").replace(/\([0-9]{2}[.:][0-9]{2}\)$/, "");
};
var getAssetMainImage = (asset) => {
  return asset.images.main;
};
var getAssetDisplays = (asset) => {
  if (isAssetUpcoming(asset) || isLiveStream(asset) || isDocumentary(asset) || isEpisode(asset)) {
    return void 0;
  }
  return asset.displays || void 0;
};
var getAssetTeaserDescription = (asset) => {
  const { category } = asset;
  if ((isDocumentary(asset) || category.isSeries) && asset.description) {
    return asset.description;
  }
  return void 0;
};
var subscriptionLabelMapping = {
  vgtv: {
    plus: "VG+",
    sport: "VG+ Sport"
  },
  ab: {
    plus: "Plus"
  }
};
var getSubscriptionLabel = (asset) => {
  const access = getAssetAccessLevel(asset);
  if (!access) {
    return void 0;
  }
  return subscriptionLabelMapping[asset.provider][access];
};
var isAssetPaywalled = (asset) => {
  return getAssetAccessLevel(asset) !== void 0;
};
var isLiveStream = (asset) => {
  return asset.streamType === "live";
};
var isWasLiveStream = (asset) => {
  return asset.streamType === "wasLive";
};
var getLiveAssetFlightTimesStart = (asset) => {
  var _a;
  if (!isLiveStream(asset)) {
    return void 0;
  }
  const { flightTimes } = asset;
  const { eventStartTime, studioStartTime } = getAssetMetadata(asset);
  if (studioStartTime) {
    return parseInt(studioStartTime, 10);
  }
  if (eventStartTime) {
    return parseInt(eventStartTime, 10);
  }
  return (_a = flightTimes == null ? void 0 : flightTimes.start) != null ? _a : void 0;
};
var getAssetFlightTimesStart = (asset) => {
  const { flightTimes } = asset;
  return (flightTimes == null ? void 0 : flightTimes.start) || void 0;
};
var getAssetPublishedDate = (asset) => {
  var _a;
  if (isLiveStream(asset) || isAssetUpcoming(asset) || isSponsoredAsset(asset)) {
    return void 0;
  }
  if (isWasLiveStream(asset)) {
    return getAssetFlightTimesStart(asset);
  }
  return (_a = asset.published) != null ? _a : void 0;
};
var isUpcomingLive = (asset, relativeTo) => {
  const start = getLiveAssetFlightTimesStart(asset);
  if (!start) {
    return false;
  }
  const nowUnixTimestamp = relativeTo || Date.now() / 1e3;
  return nowUnixTimestamp < start;
};
var isAssetUpcoming = (asset, relativeTo) => {
  const start = getAssetFlightTimesStart(asset);
  if (!start) {
    return false;
  }
  const nowUnixTimestamp = relativeTo || Date.now() / 1e3;
  return nowUnixTimestamp < start;
};
var isCurrentlyLive = (asset, relativeTo) => {
  const start = getLiveAssetFlightTimesStart(asset);
  if (!start) {
    return false;
  }
  const nowUnixTimestamp = relativeTo || Date.now() / 1e3;
  return nowUnixTimestamp >= start;
};
var assetHasTag = (asset, tagId) => {
  var _a, _b;
  return (_b = (_a = asset.additional) == null ? void 0 : _a.tags) == null ? void 0 : _b.includes(tagId);
};
var assetHasStory = (asset, storyId) => {
  var _a, _b;
  return (_b = (_a = asset.additional) == null ? void 0 : _a.stories) == null ? void 0 : _b.includes(storyId);
};
var isVerticalAsset = (asset) => {
  return asset.additional.metadata.aspectRatio === "0.56";
};
var isAssetActive = (asset) => {
  return asset.status === "active";
};
var isMock = (asset) => {
  return asset.streamType === "mock";
};
var emptyAssetMetadata = {};
Object.freeze(emptyAssetMetadata);
var getAssetMetadata = (asset) => {
  var _a;
  return ((_a = asset.additional) == null ? void 0 : _a.metadata) || emptyAssetMetadata;
};
var getSeoAssetTitle = (asset) => {
  return (getAssetMetadata(asset).seoTitle || "").trim() || asset.title;
};
var getSeoAssetDescription = (asset) => {
  const { seoDescription = "" } = getAssetMetadata(asset);
  return seoDescription.trim() || asset.description || "";
};
var getAssetShortPath = (asset) => {
  const { titleShortPath } = getAssetMetadata(asset);
  return titleShortPath || slugify(asset.title || "");
};
var getAssetByline = (asset) => {
  const { bylineName, bylineEmail } = getAssetMetadata(asset);
  if (bylineName && bylineEmail) {
    return {
      name: bylineName.trim(),
      email: bylineEmail.trim()
    };
  }
  return void 0;
};
var isSponsoredAsset = (asset) => {
  return asset.provider === "brandstudio";
};
var getAssetEmbeddedTags = (asset) => {
  return asset.additional.tags.map((tagId) => asset._embedded.tags.find(({ id }) => tagId === id)).filter((tag) => Boolean(tag));
};
var RESTRICTED_TAGS = {
  vgtv: [
    "a9934b2f-9328-4bbc-a620-0d1e81fe0b66",
    // NENT
    "1edb9ec7-7902-4ff1-a8f8-7f61d8e5bac9",
    // VG: Mangfold
    "4af46dc8-721e-4e79-884b-1818af5d8a09",
    // vg:Ung-journalistikk
    "57eff209-28df-4a1a-bf47-17c2fe4244c5"
    // Stories
  ]
};
var getAssetDisplayTags = (asset) => {
  var _a;
  const restrictedTags = (_a = RESTRICTED_TAGS[asset.provider]) != null ? _a : [];
  return getAssetEmbeddedTags(asset).filter((tag) => !restrictedTags.includes(tag.id));
};
var getAssetTags = (asset) => {
  return getAssetEmbeddedTags(asset).map((tag) => tag.tag);
};
var getCategoryAgeLimit = ({ ageLimit, isSeries }) => {
  if (ageLimit === void 0 || ageLimit === null || !isSeries) {
    return null;
  }
  return ageLimit;
};
var getAssetAgeLimit = (asset) => {
  const ageLimit = asset.additional.settings.ageLimit;
  if (ageLimit !== void 0 && ageLimit !== null) {
    return ageLimit;
  }
  return asset.series ? getCategoryAgeLimit(asset.category) : null;
};
var getAssetPreviewUrl = (asset) => {
  const { preview_vivi_category } = getAssetMetadata(asset);
  return preview_vivi_category;
};
var isAssetGeoblocked = (asset) => {
  return asset.streamConfiguration.properties.includes("geoblocked");
};
var isAssetEncrypted = (asset) => {
  return asset.streamConfiguration.properties.includes("encrypted");
};
var isAssetTokenSecured = (asset) => {
  return asset.streamConfiguration.properties.includes("tokenSecured");
};
export {
  assetHasStory,
  assetHasTag,
  getAssetAccessLevel,
  getAssetAgeLimit,
  getAssetByline,
  getAssetDisplayTags,
  getAssetDisplayTitle,
  getAssetDisplays,
  getAssetEmbeddedTags,
  getAssetFlightTimesStart,
  getAssetLevel,
  getAssetLevelName,
  getAssetMainImage,
  getAssetMetadata,
  getAssetPreviewUrl,
  getAssetPublishedDate,
  getAssetShortPath,
  getAssetTags,
  getAssetTeaserDescription,
  getLiveAssetFlightTimesStart,
  getSeoAssetDescription,
  getSeoAssetTitle,
  getSubscriptionLabel,
  isAssetActive,
  isAssetEncrypted,
  isAssetGeoblocked,
  isAssetPaywalled,
  isAssetTokenSecured,
  isAssetUpcoming,
  isCurrentlyLive,
  isDocumentary,
  isEntertainment,
  isEpisode,
  isLiveStream,
  isMock,
  isSponsoredAsset,
  isUpcomingLive,
  isVerticalAsset,
  isWasLiveStream
};
