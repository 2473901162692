import { getAssetPublishedDate, isLiveStream, isWasLiveStream } from 'asset-helpers';
import { formatDuration, formatPublishedDate } from '@vgtv/time-helpers';
import type { SvpAssetAdditional } from '@vgtv/api-client/lib/svp_asset';

export const getLabels = (asset: SvpAssetAdditional) => {
  const labels: string[] = [];

  const publishedDate = getAssetPublishedDate(asset);

  if (publishedDate) {
    labels.push(formatPublishedDate(publishedDate, 'nb'));
  }

  const duration = isLiveStream(asset) || isWasLiveStream(asset) ? undefined : asset.duration;

  if (duration) {
    labels.push(formatDuration(duration));
  }

  return labels;
};
