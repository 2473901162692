import type { FC } from 'react';
import { useEffect } from 'react';
import { Image, type ImageSources } from 'ui/Image';
import type { SvpCategoryAdditional } from '@vgtv/api-client/lib/svp_category';
import { Typography } from 'ui/Typography';
import { ChevronRight } from '@vgno/icons';

import { getCategoryUrl } from '../../utils/getCategoryUrl';
import { useCategoryTree } from '../../hooks/useCategoryTree';
import type { Vendor } from '../../types';
import { trackEvent } from '../../services/pulse';
import { createClickEvent, createViewEvent } from '../../services/pulse/events';

import styles from './SeriesLink.module.scss';

interface SeriesProps {
  category: SvpCategoryAdditional;
  vendor: Vendor;
}

const getImageSources = (category: SvpCategoryAdditional): ImageSources => {
  return {
    src: `${category.additional.image}?t[]=80q80`,
    aspectRatio: 'horizontal',
  };
};

export const SeriesLink: FC<SeriesProps> = ({ category, vendor }) => {
  const { categoryTree } = useCategoryTree();

  const subtitle = vendor === 'vgtv' && categoryTree?.categories?.get(category.parentId)?.title;

  useEffect(() => {
    trackEvent(
      createViewEvent({
        name: category.title,
        elementType: 'SeriesLink',
      }),
    );
  }, [category]);

  const handleClick = () => {
    trackEvent(
      createClickEvent({
        name: category.title,
        intent: 'Open',
        elementType: 'SeriesLink',
      }),
    );
  };

  return (
    <a
      className={styles.container}
      href={getCategoryUrl({ category, vendor })}
      target="_blank"
      onClick={handleClick}
      rel="noopener"
    >
      <div className={styles.image}>
        <Image imageSources={getImageSources(category)} isRounded />
      </div>
      <div className={styles.labels}>
        <Typography variant="title" size="medium" bold color="primary" className={styles.title}>
          {category.title}
        </Typography>
        {subtitle && (
          <Typography variant="label" size="small" bold color="secondary">
            {subtitle}
          </Typography>
        )}
      </div>
      <ChevronRight className={styles.icon} />
    </a>
  );
};
