import type { SvpAssetId } from '@vgtv/api-client/lib/svp_asset';

import type { AssetStorage } from '../components/AssetCache/AssetCache';
import { svpApi } from '../services';

const getVideoElements = () => {
  const elements = [...document.querySelectorAll<HTMLAnchorElement>('.article-extract a')];

  return elements.filter((element) => element.href.includes('/video/'));
};

const parseVideoElement = (element: HTMLAnchorElement) => {
  let assetId = parseInt(element.pathname.split('/')[2]);

  if (assetId) {
    return {
      vendor: 'vgtv' as const,
      assetId: assetId as SvpAssetId,
    };
  }

  assetId = parseInt(element.pathname.split('/')[3]);

  if (assetId) {
    return {
      vendor: 'brandstudio' as const,
      assetId: assetId as SvpAssetId,
    };
  }

  return undefined;
};

export const preloadAssetCache = async (storage: AssetStorage) => {
  const videoElements = getVideoElements();

  const observer = new IntersectionObserver(
    (entries, observer) => {
      entries.forEach(async (entry) => {
        if (!entry.isIntersecting) {
          return;
        }

        observer.unobserve(entry.target);

        const params = parseVideoElement(entry.target as HTMLAnchorElement);

        if (!params) {
          return;
        }

        const cacheKey = `${params.vendor}:${params.assetId}` as const;

        if (storage.getItem(cacheKey)) {
          return;
        }

        try {
          const asset = await svpApi.asset.getAsset(params);

          storage.setItem(cacheKey, asset);
        } catch (err) {
          console.error(`failed to preload "${cacheKey}" cache key:`, err);
        }
      });
    },
    { rootMargin: '50% 0px' },
  );

  videoElements.forEach((videoElement) => {
    observer.observe(videoElement);
  });
};
