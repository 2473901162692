import type { FC } from 'react';

import styles from './AgeLimitLabel.module.scss';

type Props = {
  ageLimit: number | null;
};

export const AgeLimitLabel: FC<Props> = ({ ageLimit }) => {
  if (ageLimit === null) {
    return null;
  }

  return <span className={styles.ageLimit}>{ageLimit || 'A'}</span>;
};
