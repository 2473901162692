import { slugify } from 'utils/dist/string';

import type { Vendor } from '../../types';

export const BRANDSTUDIO_ROUTE_PREFIX = '/annonsorinnhold';

export const getAssetPagePath = ({ vendor }: { vendor: Vendor }) => {
  let path = '/video/:assetId/*';

  if (vendor === 'brandstudio') {
    path = BRANDSTUDIO_ROUTE_PREFIX + path;
  }

  return path;
};

export const getAssetPageUrl = ({
  url,
  assetId,
  assetTitle,
  time,
  vendor,
}: {
  url: URL;
  assetId: number;
  assetTitle?: string;
  time?: number;
  vendor: Vendor;
}) => {
  url.pathname = `/video/${assetId}`;

  if (assetTitle) {
    url.pathname += `/${slugify(assetTitle)}`;
  }

  if (time) {
    url.searchParams.set('t', `${time}s`);
  }

  if (vendor === 'brandstudio') {
    url.pathname = BRANDSTUDIO_ROUTE_PREFIX + url.pathname;
  }

  return url;
};
