// src/string.ts
var replaceDiacritics = (inputString) => {
  return inputString.replace(/æ/g, "ae").replace(/ø/g, "oe").replace(/å/g, "aa").replace(/é|ê|è/g, "e").replace(/û/g, "u").replace(/î/g, "i").replace(/ô/g, "o").replace(/å/g, "a").replace(/ä/g, "a").replace(/ö/g, "o");
};
var slugify = (inputString) => {
  const lowerCase = inputString.trim().toLowerCase();
  return replaceDiacritics(lowerCase).replace(/ /g, "-").replace(/[^a-z0-9-]/g, "-").replace(/-+/g, "-").replace(/^-/, "").replace(/-$/, "");
};
export {
  replaceDiacritics,
  slugify
};
