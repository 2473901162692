import type { SvpAssetAdditional } from '@vgtv/api-client/lib/svp_asset';
import { useEffect, useState } from 'react';
import { useAbortController } from 'ui/useAbortController';

import { relatedApi } from '../services';
import { useAssetCache } from '../components/AssetCache/AssetCache';

export const RELATED_ASSETS_LIMIT = 10;

const defaultAssetsState: SvpAssetAdditional[] = [];

export const useRelatedAssets = ({ asset }: { asset?: SvpAssetAdditional }) => {
  const [assets, setAssets] = useState<SvpAssetAdditional[]>(defaultAssetsState);

  const { assetCache } = useAssetCache();
  const { getSignal } = useAbortController();

  useEffect(() => {
    setAssets(defaultAssetsState);

    if (!asset) {
      return;
    }

    (async () => {
      try {
        const filters = [];

        if (asset.provider === 'brandstudio') {
          filters.push(`category.id::${asset.category.id}`);
        } else {
          filters.push(
            'series.seasonNumber<>1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20',
            'additional.metadata.contentType<>documentary',
          );
        }

        const relatedAssets = await relatedApi.getRelatedAssets(
          {
            assetId: asset.id,
            limit: RELATED_ASSETS_LIMIT,
            vendor: asset.provider,
            filters,
          },
          { signal: getSignal() },
        );

        const assets = relatedAssets.filter((relatedAsset) => {
          if (relatedAsset.provider === 'brandstudio') {
            return asset.category.id === relatedAsset.category.id;
          }

          return !relatedAsset.series;
        });

        assets.forEach((asset) => {
          assetCache.setItem(`${asset.provider}:${asset.id}`, asset);
        });

        setAssets(assets);
      } catch (err) {
        if (err instanceof Error && err.name === 'AbortError') {
          return;
        }

        console.error(err);
      }
    })();
  }, [asset, getSignal, assetCache]);

  return { assets };
};
