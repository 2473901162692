import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DEFAULT_LABEL_SEPARATOR, Displays, getAssetLabels } from 'ui/Asset';
import { DataType } from '@vgtv/api-client/lib/component';
import type { SvpAssetAdditional } from '@vgtv/api-client/lib/svp_asset';
import { getAssetDisplayTitle, getAssetDisplays, getAssetTags, getAssetAgeLimit } from 'asset-helpers';
import { Typography } from 'ui/Typography';
import { BaseButton } from 'ui/BaseButton';

import { trackEvent } from '../../services/pulse';
import { createClickEvent } from '../../services/pulse/events';
import { ShowMoreButton } from '../ShowMoreButton/ShowMoreButton';
import { ShareButton } from '../ShareButton/ShareButton';
import { useBylineTracking } from './useBylineTracking';
import { useAuthorsGet } from '../../hooks/useAuthorsGet';
import { Bylines } from '../Bylines/Bylines';
import { AgeLimitLabel } from '../AgeLimitLabel/AgeLimitLabel';

import styles from './AssetDetails.module.scss';

interface AssetDetailsProps {
  asset: SvpAssetAdditional;
}

export const AssetDetails: FC<AssetDetailsProps> = ({ asset }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const { trackBylineView } = useBylineTracking(asset);
  const { authors, areAuthorsFetching } = useAuthorsGet({ asset });
  const { description } = asset;

  const title = useMemo(() => getAssetDisplayTitle(asset), [asset]);
  const displays = getAssetDisplays(asset);
  const labels = useMemo(() => getAssetLabels(asset, { dataType: DataType.ASSETS }), [asset]);
  const ageLimit = getAssetAgeLimit(asset);

  const handleDetailsClick = useCallback(() => {
    if (isCollapsed) {
      trackEvent(
        createClickEvent({
          name: 'ShowMoreButton',
          elementType: 'ShowMoreButton',
          tags: getAssetTags(asset),
        }),
      );
    }

    setIsCollapsed(!isCollapsed);
  }, [isCollapsed, asset]);

  useEffect(() => {
    setIsCollapsed(true);
  }, [asset.id]);

  const displayDisplays = !isCollapsed && Boolean(displays);
  const displayDescription = !isCollapsed && Boolean(description);
  const displayByline = !isCollapsed && !areAuthorsFetching && authors.length > 0;

  useEffect(() => {
    if (displayByline) {
      trackBylineView();
    }
  }, [displayByline, trackBylineView]);

  return (
    <BaseButton component="div" className={styles.container} onClick={handleDetailsClick}>
      <div className={styles.title}>
        <Typography variant="title" size="large" color="primary" bold>
          {title}
        </Typography>
        <ShareButton asset={asset} />
      </div>
      <div className={styles.metadata}>
        <Typography variant="label" size="small" bold color="secondary" className={styles.label}>
          {ageLimit !== null && (
            <>
              <AgeLimitLabel ageLimit={ageLimit} />
              {DEFAULT_LABEL_SEPARATOR}
            </>
          )}
          {labels.join(DEFAULT_LABEL_SEPARATOR)}
          {isCollapsed && <ShowMoreButton />}
        </Typography>
        {displayDisplays && <Displays displays={displays!} />}
      </div>
      {displayDescription && (
        <Typography variant="body" size="small" color="primary" className={styles.description}>
          {description}
        </Typography>
      )}
      {displayByline && <Bylines authors={authors} />}
    </BaseButton>
  );
};
