import type { FC } from 'react';
import { lazy, Suspense } from 'react';
import { Typography } from 'ui/Typography';
import { AssetHorizontalSkeleton, getAssetLabels } from 'ui/Asset';
import type { SvpAssetAdditional } from '@vgtv/api-client/lib/svp_asset';

import { RELATED_ASSETS_LIMIT, useRelatedAssets } from '../../../hooks/useRelatedAssets';
import { useTranslation } from '../../../i18n';
import { SeriesLink } from '../../SeriesLink/SeriesLink';

const AssetHorizontal = lazy(() =>
  import('ui/Asset/AssetHorizontal/AssetHorizontal').then(({ AssetHorizontal }) => ({ default: AssetHorizontal })),
);
const AssetLink = lazy(() => import('../../AssetLink/AssetLink').then(({ AssetLink }) => ({ default: AssetLink })));

import styles from './List.module.scss';

interface ListProps {
  asset?: SvpAssetAdditional;
}

export const List: FC<ListProps> = ({ asset }) => {
  const { t } = useTranslation();
  const { assets } = useRelatedAssets({ asset });

  return (
    <div>
      <div className={styles.header}>
        {asset?.category.isSeries && <SeriesLink category={asset.category} vendor={asset.provider} />}
        <Typography variant="title" size="large" color="primary" bold>
          {t('Next')}
        </Typography>
      </div>
      <div className={styles.items}>
        {assets.length > 0
          ? assets.map((asset) => {
              return (
                <Suspense fallback={<AssetHorizontalSkeleton />} key={asset.id}>
                  <AssetLink assetId={asset.id} vendor={asset.provider} assetTitle={asset.title}>
                    <AssetHorizontal asset={asset} labels={getAssetLabels(asset)} />
                  </AssetLink>
                </Suspense>
              );
            })
          : [...Array(RELATED_ASSETS_LIMIT)].map((_, index) => <AssetHorizontalSkeleton key={index} />)}
      </div>
    </div>
  );
};
