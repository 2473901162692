import type { FC } from 'react';
import { useCallback, useState } from 'react';
import cn from 'clsx';
import type { IMBOImageUrl, IMBOImageUrlWithTransform } from '@vgtv/api-client';

import styles from './Image.module.scss';

export type ImageAspectRatio =
  | 'vertical' // 3:2
  | 'horizontal' // 16:9
  | 'square'; // 1:1

export interface ImageSources {
  /**
   * base url with no transform
   */
  imageUrl?: IMBOImageUrl;
  /**
   * url with the default transform applied (use as the src attribute for an image)
   */
  src?: string;
  /**
   * a set of multiple {@link IMBOImageUrlWithTransform} combined with their intrinsic width in pixels {@link https://developer.mozilla.org/en-US/docs/Learn/HTML/Multimedia_and_embedding/Responsive_images|see MDN documentation}
   */
  srcSet?: IMBOImageUrlWithTransform;
  /**
   * a set media conditions for choosing the right image files from the given srcSet
   */
  sizes?: string;

  aspectRatio: ImageAspectRatio;
}

export interface ImageProps {
  imageSources?: ImageSources;
  onLoad?: () => void;
  className?: string;
  isRound?: boolean;
  isRounded?: boolean;
  lazy?: boolean;
  altText?: string;
}

export const Image: FC<ImageProps> = ({
  imageSources,
  onLoad,
  className,
  isRound,
  isRounded = true,
  lazy = true,
  altText = '',
}) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const displayImage = imageSources && (imageSources.srcSet || imageSources.src);

  const onImageLoad = useCallback(() => {
    setIsLoaded(true);

    if (typeof onLoad === 'function') {
      onLoad();
    }
  }, [onLoad]);

  const image = displayImage ? (
    <img
      className={styles.image}
      alt={altText}
      sizes={imageSources.sizes}
      srcSet={imageSources.srcSet}
      src={imageSources.src}
      onLoad={onImageLoad}
      loading={lazy ? 'lazy' : undefined}
    />
  ) : null;

  return (
    <div
      className={cn(
        styles.container,
        {
          [styles.isVertical]: imageSources && imageSources.aspectRatio === 'vertical',
          [styles.isSquare]: imageSources && imageSources.aspectRatio === 'square',
          [styles.isLoaded]: isLoaded,
          [styles.isRounded]: isRounded && !isRound,
          [styles.isRound]: isRound,
        },
        className,
      )}
    >
      <div className={styles.aspectRatio}>{image}</div>
    </div>
  );
};
