import type { SvpCategoryAdditional } from '@vgtv/api-client/lib/svp_category';
import { slugify } from 'utils/dist/string';

import { config } from '../config';
import type { Vendor } from '../types';
import { BRANDSTUDIO_ROUTE_PREFIX } from '../components/AssetLink/getAssetPageUrl';

const getCategoryShortPath = (category: SvpCategoryAdditional) => {
  return (category.additional.shortPath ? category.additional.shortPath : slugify(category.title)).toLowerCase();
};

const getCategoryPagePath = ({ category, vendor }: { category: SvpCategoryAdditional; vendor: Vendor }) => {
  const shortPath = getCategoryShortPath(category);

  if (vendor === 'vgtv' && category.isSeries) {
    return `/serier/${shortPath}?categoryId=${category.id}`;
  }

  let route = `/kategori/${category.id}/${shortPath}`;

  if (vendor === 'brandstudio') {
    route = BRANDSTUDIO_ROUTE_PREFIX + route;
  }

  return route;
};

export const getCategoryUrl = ({ category, vendor }: { category: SvpCategoryAdditional; vendor: Vendor }) => {
  return `https://${config.vgtv.hostname}${getCategoryPagePath({ category, vendor })}`;
};
