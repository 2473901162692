import type { EventInput } from '@spt-tracking/pulse-sdk';
import type { SvpAssetAdditional, SvpAssetId } from '@vgtv/api-client/lib/svp_asset';
import { getAssetLevel, getAssetLevelName } from 'asset-helpers';

const getObjectId = (...parts: string[]) => {
  return ['sdrn', 'vg', 'content', 'videooverlay', ...parts].join(':');
};

const getVideoViewObjectId = (assetId: SvpAssetId) => {
  return ['sdrn', 'vgtv', 'video', String(assetId)].join(':');
};

const getElementObjectId = (name: string) => {
  return getObjectId('element', name.toLowerCase());
};

export const createClickEvent = ({
  name,
  elementType,
  position,
  tags,
  intent,
}: {
  name: string;
  elementType: string;
  position?: number;
  tags?: string[];
  intent?: string;
}) => {
  return {
    type: 'Engagement',
    action: 'Click',
    intent,
    object: {
      '@id': getElementObjectId(elementType),
      '@type': 'UIElement',
      'name': name,
      'position': position,
      'elementType': elementType,
      tags,
    },
  };
};

export const createViewEvent = ({ name, elementType }: { name: string; elementType: string }) => {
  return {
    type: 'Engagement',
    intent: 'View',
    object: {
      '@id': getElementObjectId(elementType),
      '@type': 'UIElement',
      'name': name,
      'elementType': elementType,
    },
  };
};

export const createErrorEvent = ({ error }: { error: Error }) => {
  return {
    type: 'View',
    action: 'Error',
    object: {
      '@id': getObjectId('error'),
      '@type': 'Content',
      'name': String(error),
    },
  };
};

export const createViewVideoEvent = ({ asset }: { asset: SvpAssetAdditional }): EventInput => {
  const accessLevel = getAssetLevel(asset.additional?.access, asset.provider);
  const accessLevelName = getAssetLevelName(asset.additional?.access);

  return {
    type: 'View',
    object: {
      '@id': getVideoViewObjectId(asset.id),
      '@type': 'Video',
      'contentId': asset.id,
      'name': asset.title,
      'accessLevel': accessLevel,
      'accessLevelName': accessLevelName,
      'tags': asset._embedded.tags.map((tag) => tag.tag),
      'category': asset.category.title.toLocaleLowerCase(),
    },
  };
};
