import type { FC } from 'react';
import { useMemo, useCallback, lazy, Suspense } from 'react';
import type { SvpAssetAdditional } from '@vgtv/api-client/lib/svp_asset';
import { getAssetTags } from 'asset-helpers';
import { AssetVerticalSkeleton, getAssetLabels } from 'ui/Asset';
import { Typography } from 'ui/Typography';

import { RELATED_ASSETS_LIMIT, useRelatedAssets } from '../../hooks/useRelatedAssets';
import { useTranslation } from '../../i18n';
import { trackEvent } from '../../services/pulse';
import { createClickEvent } from '../../services/pulse/events';
import { DisplayAd } from '../DisplayAd/DisplayAd';
import { getItems } from './helpers';
import { SeriesLink } from '../SeriesLink/SeriesLink';

import styles from './AssetList.module.scss';

const AssetVertical = lazy(() =>
  import('ui/Asset/AssetVertical/AssetVertical').then(({ AssetVertical }) => ({ default: AssetVertical })),
);
const AssetLink = lazy(() => import('../AssetLink/AssetLink').then(({ AssetLink }) => ({ default: AssetLink })));

interface AssetListProps {
  asset?: SvpAssetAdditional;
}

export const AssetList: FC<AssetListProps> = ({ asset }) => {
  const { t } = useTranslation();
  const { assets } = useRelatedAssets({ asset });

  const items = useMemo(() => getItems(assets), [assets]);

  const handleClick = useCallback(
    (position: number) => {
      trackEvent(
        createClickEvent({
          name: 'OverlayRelatedVideo',
          elementType: 'OverlayRelatedVideoLink',
          position,
          tags: asset ? getAssetTags(asset) : [],
        }),
      );
    },
    [asset],
  );

  return (
    <div className={styles.container}>
      {asset?.category.isSeries && (
        <div className={styles.series}>
          <SeriesLink category={asset.category} vendor={asset.provider} />
        </div>
      )}
      <Typography variant="body" size="large" color="primary" bold>
        {t('Next')}
      </Typography>
      <div className={styles.list}>
        {items.length > 0
          ? items.map((item, index) => {
              if (item.type === 'asset') {
                const { asset } = item;

                return (
                  <Suspense fallback={<AssetVerticalSkeleton />} key={asset.id}>
                    <AssetLink
                      assetId={asset.id}
                      vendor={asset.provider}
                      onClick={() => handleClick(index + 1)}
                      assetTitle={asset.title}
                    >
                      <AssetVertical asset={asset} disableDescription labels={getAssetLabels(asset)} />
                    </AssetLink>
                  </Suspense>
                );
              }

              return <DisplayAd asset={asset} key={index} variant="mobile" />;
            })
          : [...Array(RELATED_ASSETS_LIMIT)].map((_, index) => <AssetVerticalSkeleton key={index} />)}
      </div>
    </div>
  );
};
