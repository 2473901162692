import { createContext, useContext } from 'react';
import type { SvpCategory } from '@vgtv/api-client/lib/svp_category';

interface CategoryTree {
  categoryTree?: {
    categories?: Map<number, SvpCategory>;
  };
}

const CategoryTreeContext = createContext<CategoryTree>(undefined!);

export const CategoryTree = CategoryTreeContext.Provider;

const fallback = {} satisfies CategoryTree;

export const useCategoryTree = () => useContext(CategoryTreeContext) ?? fallback;
